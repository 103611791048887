
import { HashLink as Link } from 'react-router-hash-link';
// import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';


const Sidebar1 = () => {
  return (
    <div className="fixed top-0 right-0 w-32 h-full bg-gray-800 text-white py-20 z-50">
      {/* Sidebar content goes here */}
      <ul className='flex flex-col gap-5'>
        <Link to="/#home" smooth={true} duration={500} className="hover:text-white cursor-pointer btn">Home</Link>
        <Link to="/#about" smooth={true} duration={500} className="hover:text-white cursor-pointer btn">About me</Link>
        <Link to="/#projects" smooth={true} duration={500} className="hover:text-white cursor-pointer btn">Projects</Link>
        <Link to="/#gallery" smooth={true} duration={500} className="hover:text-white cursor-pointer btn">Gallery</Link>
      </ul>
    </div>
  );
};

export default Sidebar1;
