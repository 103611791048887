
const FullProjects = () => {
    return (
        <div>
            dada
        </div>
    )
}

export default FullProjects;

// <a 
// href="https://grp4-smart-system.vercel.app/"
// target="_blank" 
// rel="noopener noreferrer" 
// >
// <ProjectCard 
//   title="Reconciling the Traditional and Digital: Chinese Nationalism"
//   description="Presented a poster at the poster competition, Lovelace Colloquium, in Liverpool on art and AI. "
//   skills={Lovelace}
//   img={lovelaceCover}
//   image={lovelacePoster}
//   yt="https://www.youtube.com/watch?v=3-qxv5WFke4"
// />
// </a>