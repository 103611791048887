
import ProjectCard from "../components/ProjectCard"
import herbandspice from "../assets/images/herbandspice.jpg"
import sportmate from "../assets/images/sportmate.png"
import raspberrypi from "../assets/images/RaspberryPi.jpg"
// import lovelaceCover from "../assets/images/lovelace_liverpool.jpg"
// import lovelacePoster from "../assets/images/lovelace_poster_final.png"
// import { Link } from "react-router-dom"


const Projects = () => {

  const HBskills = [
    "React", "Tailwind CSS"
  ]
  const SportmateSkills = [
    "Flutter", "Dart"
  ]
  const IoTSkills = [
    "Netx.js", "MongoDB", "RESTful API", "Tailwind CSS"
  ]
  // const Lovelace = ["Procreate"]

  return (
    <section className="relative bg-gradient-to-b from-wave-blue to-deep-blue pt-16 items-center flex flex-col">
      <h3 className="font-montserrat text-white text-4xl font-bold pt-10">
        Projects
      </h3>
      <p className="text-gray-200 mt-5 font-inter padding-x text-lg">
        Take a look at what I've been working on!
      </p>
      <div className="pt-16 gap-8 flex flex-wrap justify-center px-14">
        <a 
          href="https://grp4-smart-system.vercel.app/"
          target="_blank" 
          rel="noopener noreferrer" 
        >
          <ProjectCard 
            title="IoT Smart System"
            description="A full-stack IoT smart system using Raspberry Pi devices to monitor the environemnt, users, devices and others in the lab.  "
            skills={IoTSkills}
            img={raspberrypi}
            git="https://github.com/Colley-A/SCC_330_SLS"
            yt="https://www.youtube.com/watch?v=SPUiCte_qtw&t=2s"
          />
        </a>     
        <a
          href="https://github.com/phasuk15/Herb-and-Spice.git"
          target="_blank" 
          rel="noopener noreferrer" 
        >
          <ProjectCard 
            title="Herb and Spice"
            description="A website for my family Thai restaurant. It's not done yet tho >.<. Will be coming soon!!" 
            skills={HBskills} 
            img={herbandspice}
            git="https://github.com/phasuk15/Herb-and-Spice.git"
          />
        </a>
        <a 
          href="https://www.canva.com/design/DAF-2sI0oL8/YFMzvymPyv-KfGSXrw6FAw/view?utm_content=DAF-2sI0oL8&utm_campaign=designshare&utm_medium=link&utm_source=editor"
          target="_blank" 
          rel="noopener noreferrer" 
        >
          <ProjectCard 
            title="SportMate"
            description="A platform designed to bring sport players together by introducing matchmaking and tournament style systems to make finding sport partners easier. "
            skills={SportmateSkills}
            img={sportmate}
            git="https://github.com/stasnowaczyk/sportmate_flutter.git"
          />
        </a>
           
      </div>
      {/* <button className='pb-24 pt-12 flex justify-center text-gray-200 font-archivo mt-3 text-lg'>
        <Link to='/FullProjects' className='text-lg btn'>
          Check out more projects
        </Link>
      </button> */}
    </section>
  )
}

export default Projects